import React, { useEffect, useState } from "react";
import "./ScrollIndicator.css";

interface ScrollIndicatorProps {
  onArrowClick: () => void;
}

const ScrollIndicator: React.FC<ScrollIndicatorProps> = ({ onArrowClick }) => {
  const [isTop, setTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setTop(window.scrollY === 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (!isTop) {
    return null;
  }

  return (
    <button className="scroll-indicator" onClick={onArrowClick}>
      &#8595;
    </button>
  );
};

export default ScrollIndicator;
