// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component {
  background-color: rgba(255, 255, 255, 0.6);
  width: 30%;
  max-width: 500px;
  margin: 2rem auto;
  padding-bottom: 1em;
  font-family: "Dancing Script";
  border: 0.1px solid black;
  border-radius: 10px;
}

.playingNow {
  color: darkslategrey;
}

.musicCover {
  border-radius: 10%;
}

.playButton {
  background: none;
  border: none;
  align-items: center;
  justify-content: center;
}

.subTitle {
  margin-top: -1em;
  color: #4f4f4f;
}

.time {
  margin: 0 auto;
  width: 80%;
  display: flex;
  justify-content: space-around;
  color: #828282;
  font-size: smaller;
}

.timeline {
  width: 80%;
  background-color: #27ae60;
}

input[type="range"] {
  background-color: #27ae60;
}

@media (max-width: 900px) {
  .component {
    width: 50%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Player/Player.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,UAAU;EACV,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,6BAA6B;EAC7B,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,UAAU;EACV,aAAa;EACb,6BAA6B;EAC7B,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".component {\n  background-color: rgba(255, 255, 255, 0.6);\n  width: 30%;\n  max-width: 500px;\n  margin: 2rem auto;\n  padding-bottom: 1em;\n  font-family: \"Dancing Script\";\n  border: 0.1px solid black;\n  border-radius: 10px;\n}\n\n.playingNow {\n  color: darkslategrey;\n}\n\n.musicCover {\n  border-radius: 10%;\n}\n\n.playButton {\n  background: none;\n  border: none;\n  align-items: center;\n  justify-content: center;\n}\n\n.subTitle {\n  margin-top: -1em;\n  color: #4f4f4f;\n}\n\n.time {\n  margin: 0 auto;\n  width: 80%;\n  display: flex;\n  justify-content: space-around;\n  color: #828282;\n  font-size: smaller;\n}\n\n.timeline {\n  width: 80%;\n  background-color: #27ae60;\n}\n\ninput[type=\"range\"] {\n  background-color: #27ae60;\n}\n\n@media (max-width: 900px) {\n  .component {\n    width: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
