import React, { useEffect, useState } from "react";

type CountdownProps = {
  date: Date;
};

const Countdown: React.FC<CountdownProps> = ({ date }) => {
  const calculateTimeLeft = () => {
    const difference = +date - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        Jours: Math.floor(difference / (1000 * 60 * 60 * 24)),
        Heures: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Minutes: Math.floor((difference / 1000 / 60) % 60),
        Secondes: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer); // onDestroy cleanup
  });

  const timeEntries = Object.entries(timeLeft);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {timeEntries.map(([unit, value], index) => (
        <div
          style={{
            fontFamily: "Dancing Script",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            paddingRight: "10px",
            paddingLeft: "10px",
          }}
          key={unit}
        >
          <div>
            <div>{String(value)}</div>
            <div>{String(unit)}</div>
          </div>
          {index < timeEntries.length - 1 && (
            <div style={{ paddingLeft: "10px" }}>: </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Countdown;
