import React from "react";
import "./Header.css";

export default function Header(): JSX.Element {
  return (
    <>
      <header
        className="header"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          borderRadius: "50%",
          boxShadow: "0 30px 40px rgba(0,0,0,.1)",
        }}
      >
        <h1 className="header-title">A & C</h1>
      </header>
      <h3
        style={{
          margin: "0rem",
          color: "whitesmoke",
          fontFamily: "Dancing Script",
          fontSize: 50,
        }}
      >
        L'aventure commence !
      </h3>
    </>
  );
}
