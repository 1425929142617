import { useState } from "react";
import Inspiration from "./Inspiration";
import Modal from "react-modal";

interface IProps {
  inspirations: object[];
}

const Inspirations = ({ inspirations }: IProps) => {
  const [openImage, setOpenImage] = useState<number | null>(null);

  const closeImage = () => setOpenImage(null);

  const nextImage = () => {
    if (openImage !== null) {
      setOpenImage((currentImage: number | null) => {
        return (currentImage as number) + 1 < inspirations.length
          ? // @ts-ignore
            currentImage + 1
          : 0;
      });
    }
  };

  const previousImage = () => {
    if (openImage !== null) {
      setOpenImage((currentImage: number | null) => {
        return (currentImage as number) - 1 >= 0
          ? // @ts-ignore
            currentImage - 1
          : inspirations.length - 1;
      });
    }
  };

  return (
    <>
      <div className={"listInspiration"}>
        {inspirations.map((inspiration: any, key: number) => (
          <Inspiration
            theme={"test"}
            image={inspiration}
            key={key}
            id={key}
            onClick={() => setOpenImage(key)}
          />
        ))}
      </div>
      {openImage !== null && (
        <Modal
          isOpen={openImage != null}
          onRequestClose={closeImage}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.7)",
            },
            content: {
              display: "flex",
              inset: 70,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              border: "solid 1px white",
              overflow: "auto",
            },
          }}
        >
          <img
            // @ts-ignore
            src={inspirations[openImage]}
            alt="inspiration"
            style={{
              cursor: "pointer",
              maxWidth: "100%",
              maxHeight: "100%",
              margin: "auto",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={nextImage}
          />
          <button
            style={{
              position: "fixed",
              top: 10,
              right: 10,
            }}
            onClick={closeImage}
          >
            X
          </button>
          <button
            style={{
              position: "fixed",
              top: "50%",
              left: 10,
            }}
            onClick={previousImage}
          >
            {"\u2190"}
          </button>
          <button
            style={{
              position: "fixed",
              top: "50%",
              right: 10,
            }}
            onClick={nextImage}
          >
            {"\u2192"}
          </button>
        </Modal>
      )}
    </>
  );
};

export default Inspirations;
