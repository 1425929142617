import React from "react";

interface IProps {
  location: string;
  urlMap: string;
}

export default function LocationTitle(props: IProps) {
  return (
    <h3
      style={{
        margin: "0rem",
        color: "wheat",
        fontFamily: "Dancing Script",
        fontSize: 50,
      }}
    >
      <a
        style={{
          textDecoration: "none",
          color: "wheat",
        }}
        href={props.urlMap}
        target={"_blank"}
        rel="noreferrer"
      >
        {props.location}
        <span style={{ fontSize: "1rem" }}>🔗</span>
      </a>
    </h3>
  );
}
