// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag {
  display: inline-block;
  font-family: "Dancing Script", cursive;
  font-size: 2rem;
  margin: 10px;
}

.listTag {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tag/Tag.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,sCAAsC;EACtC,eAAe;EACf,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,6BAA6B;AAC/B","sourcesContent":[".tag {\n  display: inline-block;\n  font-family: \"Dancing Script\", cursive;\n  font-size: 2rem;\n  margin: 10px;\n}\n\n.listTag {\n  overflow: hidden;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
