// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inspiration img {
  border: 5px solid #fff;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
  margin: 30px;
  border-radius: 10px;
}

.listInspiration {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
`, "",{"version":3,"sources":["webpack://./src/components/Inspiration/Inspiration.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,0CAA0C;EAC1C,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,6BAA6B;AAC/B","sourcesContent":[".inspiration img {\n  border: 5px solid #fff;\n  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);\n  max-width: 100%;\n  max-height: 200px;\n  object-fit: cover;\n  margin: 30px;\n  border-radius: 10px;\n}\n\n.listInspiration {\n  overflow: hidden;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
