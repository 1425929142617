import Header from "../components/Header/Header";
import React, { useContext } from "react";
import Footer from "../components/Footer/Footer";
import MainTitle from "../components/Titles/MainTitle";
import Countdown from "../components/Countdown/Countdown";
import Context from "../context/Context";

export default function Menu() {
  const { informations } = useContext(Context);
  return (
    <>
      <Header />
      <MainTitle title={"Alex & Céline"} id={"main-title"} />
      <div style={{ marginBottom: "25rem" }}>
        <Countdown date={informations?.date} />
      </div>
      <MainTitle title={"Le menu"} id={"menu-title"} />
      <Footer
        phoneNumber="06.52.38.54.18"
        copyright="© 2024 Billon Prestations"
        externalLink={{ text: "Accueil", href: "/home" }}
      />
    </>
  );
}
