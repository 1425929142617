interface IProps {
  children: JSX.Element;
}

export default function Paragraph(props: IProps): JSX.Element {
  return (
    <div
      style={{
        margin: "2rem 2rem 0rem 2rem",
      }}
    >
      {props.children}
    </div>
  );
}
