import React from "react";

interface IProps {
  date: string;
}

export default function DateTitle(props: IProps) {
  return (
    <h2
      style={{
        margin: "0rem",
        color: "wheat",
        fontFamily: "Dancing Script",
        fontSize: 80,
      }}
      id={"datemariagetitle"}
    >
      {props.date}
    </h2>
  );
}
