// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: transparent;
  text-align: center;
  color: whitesmoke;
  padding: 0px;
  margin-top: 5px;
}

.header-title {
  font-size: 3rem;
  margin: 20px;
  font-family: "Dancing Script", cursive;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,sCAAsC;AACxC","sourcesContent":[".header {\n  background-color: transparent;\n  text-align: center;\n  color: whitesmoke;\n  padding: 0px;\n  margin-top: 5px;\n}\n\n.header-title {\n  font-size: 3rem;\n  margin: 20px;\n  font-family: \"Dancing Script\", cursive;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
