// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-indicator {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 3em;
  cursor: pointer;
  z-index: 10;
  background-color: transparent;
  border: none;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/ScrollIndicator.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,SAAS;EACT,2BAA2B;EAC3B,cAAc;EACd,eAAe;EACf,WAAW;EACX,6BAA6B;EAC7B,YAAY;EACZ,YAAY;AACd","sourcesContent":[".scroll-indicator {\n  position: fixed;\n  bottom: 20px;\n  left: 50%;\n  transform: translateX(-50%);\n  font-size: 3em;\n  cursor: pointer;\n  z-index: 10;\n  background-color: transparent;\n  border: none;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
