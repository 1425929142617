import React from "react";
import "./Inspiration.css";
import Modal from "react-modal";

Modal.setAppElement("#root");

interface InspirationProps {
  theme: string;
  image: string;
  id?: number;
  onClick?: any;
}

const Inspiration: React.FC<InspirationProps> = ({ theme, image, onClick }) => {
  let rotation = Math.random() * 30 - 15; // Cette ligne vous donne un nombre aléatoire entre -15 et 15 pour la rotation

  return (
    <div className="inspiration" onClick={onClick}>
      <img
        src={image}
        alt={theme}
        style={{ transform: `rotate(${rotation}deg)`, cursor: "pointer" }}
      />
    </div>
  );
};

export default Inspiration;
