import axios, { AxiosInstance, AxiosHeaders } from "axios";

class API {
  static url = "https://" + process.env.REACT_APP_HOST_API;
  headers: Partial<AxiosHeaders>;
  failedQueue: Promise<any>[];
  isRefreshing: boolean;
  api: AxiosInstance;

  constructor() {
    this.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    this.failedQueue = [];
    this.isRefreshing = false;

    this.setHeaderLocation();
    this.api = axios.create({
      baseURL: API.url,
      headers: this.headers,
    });
    this.api.defaults.timeout = 60000;
  }

  processQueue(error: any, token = null) {
    this.failedQueue.forEach((prom: any) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    this.failedQueue = [];
  }

  setHeaderLocation() {
    this.headers.frompage = window.location.pathname + window.location.search;
  }

  get(url: string, header?: any) {
    return this.api.get(url, header);
  }
}

export default API;
