import React from "react";

interface ColorPaletteProps {
  colors: string[];
}

const ColorPalette: React.FC<ColorPaletteProps> = ({ colors }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {colors.map((color, index) => (
        <div
          key={index}
          style={{ backgroundColor: color, width: 50, height: 100 }}
        />
      ))}
    </div>
  );
};

export default ColorPalette;
