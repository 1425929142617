import React from "react";

interface IProps {
  title: string;
  id?: string;
}

export default function MainTitle(props: IProps) {
  return (
    <h1
      style={{
        margin: "15rem 0rem 0rem 0rem",
        fontFamily: "Dancing Script",
        fontSize: 100,
        color: "wheat",
      }}
      id={props.id}
    >
      {props.title}
    </h1>
  );
}
