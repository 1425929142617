import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import ScrollIndicator from "./components/ScrollIndicator";
import { scrollToElement } from "./config/utils";
import routes from "./config/routes";

function App(): JSX.Element {
  const getRoutes = () => {
    return routes.map((route, key) => (
      <Route path={route.path} element={route.element} key={key} />
    ));
  };

  const renderRoutes = () => (
    <Routes>
      {getRoutes()}
      <Route path="*" element={<Navigate replace to={`/home`} />} />
    </Routes>
  );

  return (
    <>
      <ScrollIndicator
        onArrowClick={() => {
          // @ts-ignore
          scrollToElement(
            // @ts-ignore
            routes.find((route) => route.path === window.location.pathname)
              .scrollTo
          );
        }}
      />
      <div className="App">
        <div className="background-noflou"></div>
        <div className="background-flou"></div>
        {renderRoutes()}
      </div>
    </>
  );
}

export default App;
