import Home from "../pages/Home";
import Menu from "../pages/Menu";

const routes = [
  {
    path: "/home",
    name: "Alex & Céline",
    element: <Home />,
    show: true,
    scrollTo: "datemariagetitle",
  },
  {
    path: "/le-menu",
    name: "Menu | Alex & Céline",
    element: <Menu />,
    show: true,
    scrollTo: "menu-title",
  },
];

export default routes;
