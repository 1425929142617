import React from "react";

export default function TitleInspirations(): JSX.Element {
  return (
    <>
      <h3
        style={{
          margin: "1rem 0rem 1rem 0rem",
          color: "wheat",
          fontFamily: "Dancing Script",
          fontSize: 50,
        }}
      >
        Inspirations
      </h3>
    </>
  );
}
