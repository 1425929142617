import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ContextProvider from "./context/ContextProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

window.addEventListener("scroll", function () {
  var scrollTop = window.scrollY;
  var noflou: any = document.querySelector(".background-noflou");
  var flou: any = document.querySelector(".background-flou");
  var scrollFraction =
    scrollTop / (document.body.scrollHeight - window.innerHeight) + 0.2;

  noflou.style.opacity = 1 - scrollFraction;
  flou.style.opacity = scrollFraction;
});

root.render(
  <React.StrictMode>
    <ContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ContextProvider>
  </React.StrictMode>
);

reportWebVitals(console.log);
