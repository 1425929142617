import React, { useContext } from "react";
import Paragraph from "../components/Paragraph";
import TitleInspirations from "../components/Titles/TitleInspirations";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Tag from "../components/Tag/Tag";
import Player from "../components/Player/Player";
import Context from "../context/Context";
import { getRandomArray } from "../config/utils";
import ColorPalette from "../components/ColorPalette/ColorPalette";
import MainTitle from "../components/Titles/MainTitle";
import Countdown from "../components/Countdown/Countdown";
import DateTitle from "../components/Titles/DateTitle";
import LocationTitle from "../components/Titles/LocationTitle";
import Inspirations from "../components/Inspiration/Inspirations";

export default function Home(): JSX.Element {
  const { inspirations, tags, informations } = useContext(Context);

  const renderTags = () => {
    return getRandomArray(tags).map((tag: string, key: number) => (
      <Tag word={tag} key={key} />
    ));
  };

  return (
      <>
          <Header/>
          <MainTitle title={"Alex & Céline"} id={"main-title"}/>
          <div style={{marginBottom: "25rem"}}>
          {informations == null ? <div className="loader"></div> :
              <Countdown date={informations?.date}/>
          }
          </div>

          <DateTitle date={informations?.date_formatted}/>
          <LocationTitle
              location={informations?.lieu_mariage}
              urlMap={informations?.url_lieu_mariage}
          />
          <Paragraph>
              <div className="paragraph">
                  Sur le chemin parfois tortueux de la vie, Alex et Céline ont trouvé
                  leur voie l'un vers l'autre. Malgré des débuts difficiles, ils ont
                  persévéré, le cœur guidé par un amour grandissant. Aujourd'hui, cet
                  amour inquantifiable est le socle de leur union. Rejoignez-nous pour
                  célébrer leur engagement et partager leur bonheur. Bienvenue sur le
                  site de leur mariage.
              </div>
          </Paragraph>
              <TitleInspirations/>
              <Inspirations inspirations={inspirations}/>
              <div className={"listTag"}>{renderTags()}</div>
              <Player/>
              <div>
                  <h1>Palette de Couleurs</h1>
                  <ColorPalette colors={["#793b00", "#2e8a2e", "#4040c5"]}/>
              </div>
              <Footer
              phoneNumber="06.52.38.54.18"
              copyright="© 2024 Billon Prestations"
              externalLink={{text: "Le menu", href: "/le-menu"}}
          />
      </>
  );
}
