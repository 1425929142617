import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Context from "./Context";
import inspiration1 from "../assets/img/inspirations/inspiration-1.jpg";
import inspiration2 from "../assets/img/inspirations/inspiration-2.jpg";
import inspiration3 from "../assets/img/inspirations/inspiration-3.jpg";
import inspiration4 from "../assets/img/inspirations/inspiration-4.jpg";
import inspiration5 from "../assets/img/inspirations/inspiration-5.jpg";
import inspiration6 from "../assets/img/inspirations/inspiration-6.jpeg";
import inspiration7 from "../assets/img/inspirations/inspiration-7.jpg";
import inspiration8 from "../assets/img/inspirations/inspiration-8.jpg";
import inspiration9 from "../assets/img/inspirations/inspiration-9.jpg";
import inspiration10 from "../assets/img/inspirations/inspiration-10.jpg";
import inspiration11 from "../assets/img/inspirations/inspiration-11.jpg";
import API from "../config/API";

interface IProps {
  children: JSX.Element;
}

export default function ContextProvider(props: IProps): JSX.Element {
  const apiInstance = new API();

  const inspirations = [
    inspiration1,
    inspiration2,
    inspiration3,
    inspiration4,
    inspiration5,
    inspiration6,
    inspiration7,
    inspiration8,
    inspiration9,
    inspiration10,
    inspiration11,
  ];

  const [tags, setTags] = useState<Tag[]>([]);
  const [informations, setInformations] = useState<Information>();

  const loadData = async () => {
    setTags((await apiInstance.get("/tags")).data.map((tag: Tag) => tag.word));

    const resInformations: Information = (await apiInstance.get("/information"))
      .data;
    setInformations({
      ...resInformations,
      date: new Date(resInformations.date_heure_mariage),
      date_formatted: dayjs(resInformations.date_heure_mariage).format(
        "DD/MM/YYYY"
      ),
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Context.Provider
      value={{
        inspirations,
        tags,
        informations,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}
