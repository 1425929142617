import React from "react";
import "./Tag.css";

interface TagProps {
  word: string;
}

const Tag: React.FC<TagProps> = ({ word }) => {
  let rotation = Math.floor(Math.random() * 21) - 10;

  return (
    <div className="tag" style={{ transform: `rotate(${rotation}deg)` }}>
      <strong>#{word}</strong>
    </div>
  );
};

export default Tag;
