import React from "react";

interface FooterProps {
  phoneNumber: string;
  copyright: string;
  externalLink: {
    text: string;
    href: string;
  };
}

export default function Footer({
  phoneNumber,
  copyright,
  externalLink,
}: FooterProps) {
  return (
    <div
      style={{
        boxShadow: "40px -150px 150px -150px rgba(0, 0, 0, 0.3) inset",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        color: "white",
        marginTop: 50,
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 10px",
        boxSizing: "border-box",
        fontFamily: "Indie Flower, cursive", // This is a Google Font similar to handwriting
        fontSize: "0.6em", // Reduce the font size
      }}
    >
      <p>Téléphone: {phoneNumber}</p>
      <a style={{ color: "white" }} href={externalLink.href}>
        {externalLink.text}
      </a>
      <p style={{ fontSize: "0.5em" }}>{copyright}</p>
    </div>
  );
}
